<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'

defineProps<{
	registrations: Paginator<App.Data.Registration.RegistrationData>
	relatedRegistrationLines?: App.Data.Registration.RegistrationLineData[]
}>()
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-2">
		<template v-if="! isEmpty(relatedRegistrationLines)">
			<heading3>{{ t('app.registrations_for_you') }}</heading3>
			<p class="text-sm">
				{{ t('app.related_registrations_description') }}
			</p>
			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0 font-bold">
							{{ t('app.event') }}
						</table-column>
						<table-column class="font-bold">
							{{ t('app.for') }}
						</table-column>
						<table-column class="font-bold">
							{{ t('app.by') }}
						</table-column>
						<table-column class="font-bold">
							{{ t('app.category') }}
						</table-column>
					</table-row>
				</template>
				<table-row v-for="relatedRegistrationLine in relatedRegistrationLines" :key="relatedRegistrationLine.uuid">
					<table-column class="pl-0">
						{{ relatedRegistrationLine.event_name }}
					</table-column>
					<table-column>
						{{ relatedRegistrationLine.full_name }}
					</table-column>
					<table-column>
						{{
							relatedRegistrationLine.entity_name ?
								relatedRegistrationLine.entity_name :
								(relatedRegistrationLine.user_name ? relatedRegistrationLine.user_name : '')
						}}
					</table-column>
					<table-column>
						{{ relatedRegistrationLine.event_category }}
					</table-column>
				</table-row>
			</table-base>

			<heading3>{{ t('app.registrations_by_you') }}</heading3>
		</template>
		<template v-if="registrations.data.length">
			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0">
							{{ t('app.number') }}
						</table-column>
						<table-column>{{ t('app.status') }}</table-column>
						<table-column>{{ t('app.event') }}</table-column>
						<table-column>{{ t('app.created_at') }}</table-column>
						<table-column />
					</table-row>
				</template>
				<table-row v-for="registration in registrations.data" :key="registration.uuid">
					<table-column class="pl-0">
						<button-base variant="link" :href="registration.url">
							#{{ registration.id }}
						</button-base>
					</table-column>
					<table-column :class="{ 'font-bold text-green-800': registration.status === 'completed' }">
						{{ t('app.registration_status_' + registration.status) }}
					</table-column>
					<table-column>
						<button-base v-if="registration.event" variant="link" :href="route('events.show', { event: registration.event.uuid })">
							{{ registration.event.name }}
						</button-base>
					</table-column>
					<table-column>{{ registration.created_at }}</table-column>
					<table-column class="text-right">
						<template v-if="can(registration, 'view')">
							<a :href="route('events.registrations.download', { registration })" target="_blank" :title="t('app.download_pdf')" class="text-primary hover:text-primary-700">
								{{ t('app.download_pdf') }}
							</a>

							<span class="px-2 text-gray-400">|</span>
						</template>
						<button-base variant="link" :href="registration.url">
							{{ t('app.view') }}
						</button-base>
					</table-column>
				</table-row>
			</table-base>

			<pagination :links="registrations.links" />
		</template>
		<p v-else class="text-md">
			{{ t('app.no_results_found') }}
		</p>
	</page-container>
</template>

<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'
import { c2c } from 'vue-c2c'
import RegistrationTotalData = App.Data.Registration.RegistrationTotalData
import Confirm from '@/components/confirm.vue'

const $props = defineProps<{
	registration: App.Data.Registration.RegistrationData & {
		event: App.Data.EventData
		registration_lines: App.Data.Registration.RegistrationLineData[]
	}
	totals: {
		[key: string]: RegistrationTotalData
	}
}>()

defineLayoutProperties({
	pageTitle: t('app.registration_title', { number: $props.registration.id, event: $props.registration.event?.name }),
})

const cancelRegistrationLine = ref<App.Data.Registration.RegistrationLineData | null>(null)
const useConfirm = c2c(Confirm)
const { hide, toggle, visible, exposed } = useConfirm({
	title: t('app.cancel'),
	content: t('app.rider_cancel_confirm'),
	confirmText: t('app.rider_cancel_confirm_confirm_button'),
	cancelText: t('app.rider_cancel_confirm_close_button'),
}, {
	emits: {
		onOk() {
			if (!cancelRegistrationLine.value) {
				return
			}

			router.delete(route('registrationLines.cancel', { registrationLine: cancelRegistrationLine.value.uuid }), {
				preserveState: true,
				preserveScroll: true,
			})

			cancelRegistrationLine.value = null
		},
		// @ts-ignore-next-line
		onCancel() {
			cancelRegistrationLine.value = null
		},
	},
})
watch(cancelRegistrationLine, (newValue) => {
	if (newValue) {
		toggle()
	} else {
		hide()
	}
})

const registrationLines = useProperty<App.Data.Registration.RegistrationLineData[]>('registration.registration_lines')
const { searchQuery, filteredRegistrationLines } = useRegistrationLineSearch(registrationLines)
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container>
		<div class="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
			<div>
				<heading3 class="mb-4">
					{{ t('app.details') }}
				</heading3>
				<div class="grid grid-cols-2 gap-x-4 gap-y-8">
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.finished_at') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ registration.finished_at }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.status') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ t('app.registration_status_' + registration.status) }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.late_entry') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ registration.late_entry ? t('app.yes') : t('app.no') }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.extra_late_entry') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ registration.extra_late_entry ? t('app.yes') : t('app.no') }}
						</dd>
					</div>
					<div class="col-span-2 text-sm">
						<a :href="route('events.registrations.download', { registration })" target="_blank" :title="t('app.download_pdf')" class="flex items-center text-primary hover:text-primary-700">
							<i-gg-file-document class="mr-1 h-4 w-4" />
							{{ t('app.download_pdf') }}
						</a>
					</div>
				</div>
			</div>

			<div v-if="registration.payment_status">
				<heading3 class="mb-4">
					{{ t('app.payment_details') }}
				</heading3>
				<div class="grid grid-cols-2 gap-x-4 gap-y-8">
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.payment_status') }}
						</dt>
						<dd class="mt-1 flex gap-2 text-sm text-gray-900">
							{{ t('app.registration_payment_status_' + registration.payment_status) }}
							<button-base v-if="registration.authorization.retryPayment" variant="link" :href="route('events.registrations.retry', { registration: registration.uuid })">
								{{ t('app.retry') }}
							</button-base>
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.payment_method') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ t('app.registration_payment_method_' + registration.payment_method) }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.payment_date') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ registration.payment_date }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.total_registration_fee') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							&euro; {{ registration.total_registration_fee }}
						</dd>
					</div>
					<template v-if="registration.additional_fees">
						<div v-for="(additionalFee, index) in registration.additional_fees" :key="index">
							<dt class="text-sm font-medium text-gray-500">
								{{ additionalFee.description }}
								<small v-if="additionalFee.ioc_code" class="-mt-1.5 block text-gray-500">
									{{ t('app.for_x_riders_from', { count: additionalFee.count, country: t('countries.ioc.' + additionalFee.ioc_code.toUpperCase()) }) }}
								</small>
							</dt>
							<dd class="mt-1 text-sm text-gray-900">
								&euro; {{ additionalFee.price }}
							</dd>
						</div>
					</template>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.total_payment_fee') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							&euro; {{ registration.total_payment_fee }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.total_price_with_fees') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							&euro; {{ registration.total_price_with_fees }}
						</dd>
					</div>
				</div>
			</div>

			<div v-if="registration.refund_status && !registration.event.disable_refund">
				<heading3 class="mb-4">
					{{ t('app.refund_details') }}
				</heading3>
				<div class="grid grid-cols-2 gap-x-4 gap-y-8">
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.refund_status') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ t('app.registration_refund_status_' + registration.refund_status) }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.refund_date') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							{{ registration.refund_date }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.total_cancellation_fee') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							&euro; {{ registration.total_cancellation_fee }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.total_refund_fee') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							&euro; {{ registration.total_refund_fee }}
						</dd>
					</div>
					<div>
						<dt class="text-sm font-medium text-gray-500">
							{{ t('app.total_refund_price') }}
						</dt>
						<dd class="mt-1 text-sm text-gray-900">
							&euro; {{ registration.total_refund_price }}
						</dd>
					</div>
				</div>
			</div>
		</div>

		<template v-if="registration.registration_lines.length">
			<hr class="mb-4" />

			<div class="mb-2 items-center justify-between sm:flex">
				<div>
					<heading3 class="flex items-center">
						{{ t('app.registration_lines') }}
						<!--						<small class="ml-1 font-normal text-gray-500">({{ registration.registration_lines.length }})</small>-->
					</heading3>
					<div class="flex flex-wrap items-center gap-2 text-sm text-gray-500">
						<div v-for="(total, key) in totals" :key="'bottom-total' + key">
							<template v-if="key !== 'subtotal'">
								{{ t('app.total') }} {{ total.description }} <template v-if="total.count > 0">
									{{ total.count }}
								</template>
								<div v-if="registration.payment_status" class="ml-1 inline-block">
									[&euro; {{ total.price }}]
								</div>
							</template>
						</div>
						<div v-if="registration.payment_status">
							{{ t('app.subtotal') }} &euro; {{ totals['subtotal']?.price }}
						</div>
					</div>
				</div>

				<div class="relative">
					<form-input-text v-model="searchQuery" :placeholder="t('app.search_for_a_rider')" class="pr-8 sm:max-w-[250px]" />

					<i-gg-search class="absolute right-2 top-1/2 -translate-y-1/2 select-none text-gray-400" />
				</div>
			</div>

			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0" />
						<table-column>
							{{ t('app.name') }}
						</table-column>
						<table-column>{{ t('app.category') }}</table-column>
						<table-column>{{ t('app.cancelled') }}</table-column>
						<table-column v-if="registration.payment_status">
							{{ t('app.price') }}
						</table-column>
						<table-column>{{ registration.event.rider_organisation ? t(`app.organisation_id.${registration.event.rider_organisation}`) : t('app.organisation_id.fallback') }}</table-column>
					</table-row>
				</template>
				<table-row v-if="isEmpty(filteredRegistrationLines)">
					<table-column class="pl-0" colspan="6">
						{{ t('app.no_results_found') }}
					</table-column>
				</table-row>
				<table-row v-for="registrationLine in filteredRegistrationLines" :key="registrationLine.uuid">
					<table-column class="pl-0">
						<button-base v-if="!registrationLine.cancelled_at && can(registration, 'cancel')" variant="link" @click="cancelRegistrationLine = registrationLine">
							{{ t('app.cancel') }}
						</button-base>
					</table-column>
					<table-column>
						{{ registrationLine.full_name }}
					</table-column>
					<table-column>{{ registrationLine.event_category }}</table-column>
					<table-column :class="{ 'text-red-500': registrationLine.cancelled_at }">
						{{ registrationLine.cancelled_at ? t('app.yes') : t('app.no') }}
					</table-column>
					<table-column v-if="registration.payment_status">
						<price>{{ registrationLine.price }}</price>
					</table-column>
					<table-column>{{ registrationLine.organisation_id }}</table-column>
				</table-row>
			</table-base>
		</template>
	</page-container>
</template>
